import React, { useState } from "react";
// import Geocode from "react-geocode";

import { compose, withProps, lifecycle } from "recompose";
import {
  GoogleMap,
  Marker,
  withScriptjs,
  withGoogleMap,
} from "react-google-maps";
import { SearchBox } from "react-google-maps/lib/components/places/SearchBox";

import ManageInput from "../input/ManageInput.jsx";

import { Box } from "@material-ui/core";
import Utils from "../../../utils/utils";

const { REACT_APP_GOOGLE_MAP_API_KEY } = process.env;
const URL =
  "https://maps.googleapis.com/maps/api/js?key=" +
  REACT_APP_GOOGLE_MAP_API_KEY +
  "&v=3.exp&libraries=geometry,drawing,places";

// Geocode.setApiKey(REACT_APP_GOOGLE_MAP_API_KEY);

const CustomGoogleMap = compose(
  withProps({
    googleMapURL: URL,
    loadingElement: <div style={{ width: "100%", height: "100%" }} />,
    containerElement: <div style={{ width: "100%", height: "100%" }} />,
    mapElement: <div style={{ width: "100%", height: `100%` }} />,
  }),
  lifecycle({
    componentWillMount() {
      const refs = {};
      let center = this.props.center
        ? this.props.center
        : null;

      this.setState({
        getRefs: () => {
          return refs;
        },
        getCenter: () => {
          return center;
        },
        onMapMounted: (ref) => {
          refs.map = ref;
        },
        onSearchBoxMounted: (ref) => {
          refs.searchBox = ref;
        },
        onMarkerMounted: (ref) => {
          refs.marker = ref;
        },
        onDragEnd: (ref) => {
          let position = refs.marker.getPosition();
          let pos = {
            lat: position.lat(),
            lng: position.lng(),
          };

          // Geocode.fromLatLng(pos.lat, pos.lng).then(
          //   response => {
          //     let address = '';
          //     if(response && response.results && response.results.length > 0){
          //       if(response.results[0].formatted_address){
          //         address = response.results[0].formatted_address;
          //       } else if(response.results[0].name){
          //         address = response.results[0].name;
          //       }
          //     }

          //
          //   },
          //   error => {
          //     console.info(error);
          //   }
          // );

          if (this.props.onDragEnd) {
            this.props.onDragEnd(pos);
          }
        },
        onPlacesChanged: (value) => {
          let places = refs.searchBox.getPlaces();
          
          let lat = (places && places.length > 0 && places[0].geometry && places[0].geometry.location) ? places[0].geometry.location.lat() : '';
          let lng = (places && places.length > 0 && places[0].geometry && places[0].geometry.location) ? places[0].geometry.location.lng() : '';

          if(Utils.validateCoordinates(value)){
            let args = value.split(',');

            center = {
              lat: (args && args.length > 0) ? parseFloat(args[0]) : '',
              lng: (args && args.length > 1) ? parseFloat(args[1]) : '',
            };

            if (this.props.onEnterCoordinate) {
              this.props.onEnterCoordinate(places && places.length > 0 ? places[0] : null, center ? center : null);
            }
          } else {
            center = places && places[0]
              ? {
                  lat: lat,
                  lng: lng,
                }
              : null;

            if (this.props.onPlacesChanged) {
              this.props.onPlacesChanged(
                places && places.length > 0 ? places[0] : null
              );
            }
          }
        },
      });
    },
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  const [className] = useState(props.className ? props.className : "");
  const [center, setCenter] = useState(
    props.center ? props.center : null
  );
  const [placeholder] = useState(props.placeholder ? props.placeholder : "");
  const [value, setValue] = useState(props.value ? props.value : "");
  const [startAdornment] = useState(
    props.startAdornment ? props.startAdornment : null
  );
  const [endAdornment] = useState(
    props.endAdornment ? props.endAdornment : null
  );
  const [defaultZoom] = useState(props.defaultZoom ? props.defaultZoom : 16);
  const [markerCenter, setMarkerCenter] = useState(props.markerCenter ? props.markerCenter : null);
  const [isSearchable] = useState(((props.isSearchable === false) || (props.isSearchable === true)) ? props.isSearchable : true);

  const [streetViewControl] = useState(((props.streetViewControl === false) || (props.streetViewControl === true)) ? props.streetViewControl : false);
  const [mapTypeControl] = useState(((props.mapTypeControl === false) || (props.mapTypeControl === true)) ? props.mapTypeControl : false);

  React.useEffect(() => {
    if(props.onLoad){
      props.onLoad(props.getRefs());
    }
  }, []);

  React.useEffect(() => {
    setCenter(props.center ? props.center : null);
  }, [ props.center ]);
  
  React.useEffect(() => {
    setMarkerCenter(props.markerCenter ? props.markerCenter : null);
  }, [ props ]);

  const googleMap = () => {
    return (
      <div>
        <GoogleMap
          ref={props.onMapMounted}
          onCenterChanged={props.onCenterChanged}
          defaultZoom={defaultZoom}
          defaultCenter={{ lat: 1.353915, lng: 103.822901 }}
          defaultOptions={{
            disableDefaultUI: true,
            disableDoubleClickZoom: true,
            rotateControl: false,
            fullscreenControl: true,
            streetViewControl: streetViewControl,
            streetViewControlOptions: {
              position: 7,
            },
            mapTypeControl: mapTypeControl,
          }}
          center={center}
        >
          {isSearchable && searchBox()}
          {(markerCenter) && (
            <Marker
              ref={props.onMarkerMounted}
              onDragEnd={props.onDragEnd}
              draggable={true}
              position={markerCenter}
            />
          )}
        </GoogleMap>
      </div>
    );
  };

  const searchBox = () => {
    return (
      <div>
        <SearchBox
          ref={props.onSearchBoxMounted}
          onPlacesChanged={() => props.onPlacesChanged(value)}
          controlPosition={1}
        >
          <Box p={2}>
            <ManageInput
              className={"custom-google-search " + className}
              value={value}
              placeholder={placeholder}
              startAdornment={startAdornment}
              endAdornment={endAdornment}
              onChange={(e) => {
                setValue(e.target.value);
              }}
              onPressEnter={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            />
          </Box>
        </SearchBox>
      </div>
    );
  };

  return googleMap();
});

export default CustomGoogleMap;
