/* eslint-disable no-loop-func */
import { createSlice } from "@reduxjs/toolkit";
import _ from 'lodash';

import * as dispatchCrudApi from '../../../api/CRUD/DispatchCRUD'
import Utils from "../../../utils/utils";
import { BillingTypes } from "../../../utils/enums";

import invoiceNumberForm from './invoiceNumberForm';
import checkboxCount from "../../../../setup/redux/slices/checkboxCount";


function NewReducer() {
  const name = 'invoiceNumber';


  const initialState = {
    show: false,
    billingType: null,
    params: null,
    status: false,
    selectedTab: 1,
    
    isLoading: false,
    items: [],
    failedItems: [],
    successItems: [],

    weightChitJobIds: [],
  };


  const reducers = {
    show: (state, action) => {
      let data = action.payload;

      state.isLoading = true;
      state.items = [];
      state.failedItems = [];
      state.successItems = [];
      state.weightChitJobIds = [];

      state.show = true;
      state.billingType = data.billingType;
      state.status = false;
      state.selectedTab = 1;
    },
    hide: (state, action) => {
      state.isLoading = false;
      state.items = [];
      state.failedItems = [];
      state.successItems = [];
      state.weightChitJobIds = [];
      
      state.show = false;
      state.billingType = null;
      state.params = null;
      state.status = false;
      state.selectedTab = null;
    },
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
    changeCustomerValue: (state, action) => {
      let customer = action.payload;
      if(customer){
        let failedItems = state.failedItems;
        let index = failedItems.findIndex(x => x.customerId == customer.customerId);
        if(index > -1){
          failedItems[index] = customer;
        }

        state.failedItems = failedItems;
      }
    },
    changeWeightChitJobIds: (state, action) => {
      let customerSiteId = action.payload.customerSiteId;
      let jobId = action.payload.jobId;
      let arr = state.weightChitJobIds;

      let siteIndex = arr.findIndex(x => x.customerSiteId == customerSiteId);
      if (siteIndex > -1) {
        let jobIndex = arr[siteIndex].jobs.findIndex(x => x == jobId);
        if (jobIndex > -1) {
          arr[siteIndex].jobs.splice(jobIndex, 1);
        } else {
          arr[siteIndex].jobs.push(jobId);
        }
      } else {
        arr.push({
          customerSiteId: customerSiteId,
          jobs: [jobId]
        });
      }

      state.weightChitJobIds = arr;
    },
    selectAllWeightChitJobIds: (state, action) => {
      let checked = action.payload.checked;
      let customerSiteId = action.payload.customerSiteId;
      let ids = action.payload.ids;
      let arr = state.weightChitJobIds;

      let siteIndex = arr.findIndex(x => x.customerSiteId == customerSiteId);
      if (siteIndex > -1) {
        if (checked) {
          arr[siteIndex].jobs = ids;
        } else {
          arr[siteIndex].jobs = [];
        }
      } else {
        arr.push({
          customerSiteId: customerSiteId,
          jobs: checked ? ids : []
        });
      }
    },
    

    startJobs: (state, action) => {
      let data = action.payload;

      state.isLoading = true;
      state.items = [];
      state.failedItems = [];
      state.successItems = [];

      state.show = true;
      state.billingType = data.billingType;
      state.params = data.params;
      state.status = false;
      state.selectedTab = 1;
    },
    finishJobs: (state, action) => {
      let data = action.payload.data;
      let list = (data && data.data && data.data.length > 0) ? data.data : [];
      
      /**
       * I initialize the invoiceDate and invoiceNumber fields because they don't exist in API
       */
      for(let i = 0; i < list.length; i++){
        let billingProfile = (list[i] && list[i].customer && list[i].customer.billingProfile) ? list[i].customer.billingProfile : null;
        billingProfile['billingType'] = action.payload.billingType;
        billingProfile['invoiceDate'] = new Date();

        let arr = [];
        // let invoicePrefix = '';
        let invoiceNumber = '';
        try {
          let invNumb = list[i]['invoiceNumber'];
          arr = (invNumb && invNumb != '') ? invNumb.split('-') : [];

          // invoicePrefix = (arr && arr.length > 0) ? arr[0] : '';
          invoiceNumber = (arr && arr.length > 1) ? arr[1] : '';
        } catch(e){}
        
        // let expensePrefix = '';
        let expenseNumber = '';
        try {
          let invNumb = list[i]['expenseNumber'];
          arr = (invNumb && invNumb != '') ? invNumb.split('-') : [];

          // expensePrefix = (arr && arr.length > 0) ? arr[0] : '';
          expenseNumber = (arr && arr.length > 1) ? arr[1] : '';
        } catch(e){}

        // let purchaseOrderPrefix = '';
        let purchaseOrderNumber = '';
        try {
          let invNumb = list[i]['purchaseOrderNumber'];
          arr = (invNumb && invNumb != '') ? invNumb.split('-') : [];

          // purchaseOrderPrefix = (arr && arr.length > 0) ? arr[0] : '';
          purchaseOrderNumber = (arr && arr.length > 1) ? arr[1] : '';
        } catch(e){}

        billingProfile['invoiceNumber'] = (action.payload.billingType == BillingTypes.Purchase) ? purchaseOrderNumber : (action.payload.billingType == BillingTypes.Expense) ? expenseNumber : invoiceNumber;

        list[i].customer.billingProfile = billingProfile;
        
        try {
          list[i].customer.xeroPaymentTerm = list[i].customer.xeroPaymentTerm.toString();
          list[i].customer.xeroPaymentTermType = list[i].customer.xeroPaymentTermType.toString();
        } catch(e){
          list[i].customer.xeroPaymentTerm = '0';
          list[i].customer.xeroPaymentTermType = '0';
        }

        list[i]['hasBillable'] = list[i].siteJobs.some(site => site.jobs.some(x => x.isBillable));
      }

      state.items = list;
      state.isLoading = false;
    },


    startCreateInvoices: (state, action) => {
      state.status = false;
      state.isLoading = true;
      state.failedItems = [];
      state.successItems = [];
    },
    cancelCreateInvoices: (state, action) => {
      state.isLoading = false;
    },
    finishCreateInvoices: (state, action) => {
      let data = action.payload;
      
      let items = data.items;
      let failedItems = data.failedItems;
      let successItems = data.successItems;

      state.isLoading = false;
      state.items = items;
      state.failedItems = failedItems;
      state.successItems = successItems;
      state.selectedTab = (failedItems.length > 0) ? 2 : 3;
      state.status = true;
    },
    
    
    startPushInvoices: (state, action) => {
      state.status = false;
      state.isLoading = false;
      state.failedItems = [];
      state.successItems = [];
    },
    cancelPushInvoices: (state, action) => {
      state.isLoading = false;
    },
    finishPushInvoices: (state, action) => {
      let data = action.payload;
      
      let items = data.items;
      let failedItems = data.failedItems;
      let successItems = data.successItems;

      state.isLoading = false;
      state.items = items;
      state.failedItems = failedItems;
      state.successItems = successItems;
      state.selectedTab = (failedItems.length > 0) ? 2 : 3;
      state.status = true;
    },

    
    startReadFailedJobs: (state, action) => {
      state.isLoading = true;
    },
    finishReadFailedJobs: (state, action) => {
      let data = action.payload.data;
      let list = (data && data.data && data.data.length > 0) ? data.data : [];
      
      let failedItems = state.failedItems;
      if(failedItems && failedItems.length > 0){
        for(let i = 0; i < failedItems.length; i++){
          // let invoicePrefix = '';
          let invoiceNumber = '';
          try {
            let index = list.findIndex(x => x.customerId == failedItems[i].customerId);
            if(index > -1){
              let invNumb = list[index]['invoiceNumber'];
              let arr = (invNumb && invNumb != '') ? invNumb.split('-') : [];
  
              // invoicePrefix = (arr && arr.length > 0) ? arr[0] : '';
              invoiceNumber = (arr && arr.length > 1) ? arr[1] : '';
            }
          } catch(e){}

          // let expensePrefix = '';
          let expenseNumber = '';
          try {
            let index = list.findIndex(x => x.customerId == failedItems[i].customerId);
            if(index > -1){
              let invNumb = list[index]['expenseNumber'];
              let arr = (invNumb && invNumb != '') ? invNumb.split('-') : [];
  
              // expensePrefix = (arr && arr.length > 0) ? arr[0] : '';
              expenseNumber = (arr && arr.length > 1) ? arr[1] : '';
            }
          } catch(e){}

          // let purchaseOrderPrefix = '';
          let purchaseOrderNumber = '';
          try {
            let index = list.findIndex(x => x.customerId == failedItems[i].customerId);
            if(index > -1){
              let invNumb = list[index]['purchaseOrderNumber'];
              let arr = (invNumb && invNumb != '') ? invNumb.split('-') : [];
  
              // purchaseOrderPrefix = (arr && arr.length > 0) ? arr[0] : '';
              purchaseOrderNumber = (arr && arr.length > 1) ? arr[1] : '';
            }
          } catch(e){}

          
          if(failedItems[i].customer && failedItems[i].customer.billingProfile && failedItems[i].customer.billingProfile.invoiceNumber){
            failedItems[i].customer.billingProfile.invoiceNumber = (action.payload.billingType == BillingTypes.Purchase) ? purchaseOrderNumber : (action.payload.billingType == BillingTypes.Expense) ? expenseNumber : invoiceNumber;
          }
        }
      }

      state.failedItems = failedItems;
      state.isLoading = false;
    },


    startRetry: (state, action) => {
      state.isLoading = true;
    },
    cancelRetry: (state, action) => {
      state.isLoading = false;
    },
    finishRetry: (state, action) => {
      let data = action.payload.data;
      let params = state.failedItems;


      if(data && data.failedJobs && data.failedJobs.length > 0){
        params = params.map((customer) => {
          let updatedSiteJobs = customer.siteJobs.map((site) => {
            let updatedJobs = site.jobs.map((job) => {
              if (data.failedJobs.map(x => x.jobId).includes(job.jobId)) {
                return {
                  ...job,
                  response: data.failedJobs.find(x => x.jobId == job.jobId),
                  isOK: 'false'
                };
              }
              return job;
            });
      
            return {
              ...site,
              jobs: updatedJobs,
            };
          });
      
          return {
            ...customer,
            siteJobs: updatedSiteJobs,
          };
        });
      }

      if(data && data.successJobs && data.successJobs.length > 0){
        params = params.map((customer) => {
          let updatedSiteJobs = customer.siteJobs.map((site) => {
            let updatedJobs = site.jobs.map((job) => {
              if (data.successJobs.map(x => x.jobId).includes(job.jobId)) {
                return {
                  ...job,
                  response: data.successJobs.find(x => x.jobId == job.jobId),
                  isOK: 'true'
                };
              }
              return job;
            });
      
            return {
              ...site,
              jobs: updatedJobs,
            };
          });
      
          return {
            ...customer,
            siteJobs: updatedSiteJobs,
          };
        });
      }


      let failedItems = params.filter((customer) =>
        customer.siteJobs.some((site) =>
          site.jobs.some((job) => job.isOK == 'false')
        )
      );

      let successItems = params.filter((customer) =>
        customer.siteJobs.some((site) =>
          site.jobs.some((job) => job.isOK == 'true')
        )
      );

      let sItems = state.successItems;
      let successItms = sItems.concat(successItems);
      const successItmsDistinct = _.uniqBy(successItms, 'customerId');

      state.isLoading = false;
      state.failedItems = failedItems;
      state.successItems =  successItmsDistinct;
      // state.items = params;
      state.selectedTab = (failedItems.length > 0) ? 2 : 3;
    },

    startRetryAll: (state, action) => {
      state.isLoading = true;
    },
    cancelRetryAll: (state, action) => {
      state.isLoading = false;
    },
    finishRetryAll: (state, action) => {
      let data = action.payload.data;
      let params = state.failedItems;


      if(data && data.failedJobs && data.failedJobs.length > 0){
        params = params.map((customer) => {
          let updatedSiteJobs = customer.siteJobs.map((site) => {
            let updatedJobs = site.jobs.map((job) => {
              if (data.failedJobs.map(x => x.jobId).includes(job.jobId)) {
                return {
                  ...job,
                  response: data.failedJobs.find(x => x.jobId == job.jobId),
                  isOK: 'false'
                };
              }
              return job;
            });
      
            return {
              ...site,
              jobs: updatedJobs,
            };
          });
      
          return {
            ...customer,
            siteJobs: updatedSiteJobs,
          };
        });
      }

      if(data && data.successJobs && data.successJobs.length > 0){
        params = params.map((customer) => {
          let updatedSiteJobs = customer.siteJobs.map((site) => {
            let updatedJobs = site.jobs.map((job) => {
              if (data.successJobs.map(x => x.jobId).includes(job.jobId)) {
                return {
                  ...job,
                  response: data.successJobs.find(x => x.jobId == job.jobId),
                  isOK: 'true'
                };
              }
              return job;
            });
      
            return {
              ...site,
              jobs: updatedJobs,
            };
          });
      
          return {
            ...customer,
            siteJobs: updatedSiteJobs,
          };
        });
      }


      let failedItems = params.filter((customer) =>
        customer.siteJobs.some((site) =>
          site.jobs.some((job) => job.isOK == 'false')
        )
      );

      let successItems = params.filter((customer) =>
        customer.siteJobs.some((site) =>
          site.jobs.some((job) => job.isOK == 'true')
        )
      );

      let sItems = state.successItems;
      let successItms = sItems.concat(successItems);
      const successItmsDistinct = _.uniqBy(successItms, 'customerId');

      state.isLoading = false;
      state.failedItems = failedItems;
      state.successItems =  successItmsDistinct;
      // state.items = params;
      state.selectedTab = (failedItems.length > 0) ? 2 : 3;
    },
  };


  const extraReducers = (builder) => {
    /**
     * When the form is set in the invoiceNumberForm slice, we also set the items in the invoiceNumber slice
     */
    builder.addCase(invoiceNumberForm.setForm, (state, action) => {
      let items = state.items;
      items = action.payload;
      state.items = items;
    });
  };


  const apis = {
    callJobsApi: (params = null) => async (dispatch) => {
      let data = (params && params.data != null) ? _.cloneDeep(params.data) : null;

      let arr = (data && data.jobIds && data.jobIds.length > 0) ? data.jobIds : [];
      let billingType = (data && data.billingType != null) ? data.billingType : null;

      dispatch(actions.startJobs({ arr: arr, billingType: billingType, params: params }));

      let ids = arr.join(',').replace(/,+(?=,|$)/g, '').trim();

      data['jobIds'] = ids;
      data['includeJobs'] = true;

      await dispatchCrudApi.createApi(data, 'job/billing/customer').then(result => {
        let data = result.data;
        
        dispatch(actions.finishJobs({ data: data, errorMessage: '', isError: false, billingType: billingType }));
      }).catch(error => {
        let isError = true;
        let errorMessage = "";

        Utils.parseError(error, (result) => {
          isError = result.isError;
          errorMessage = result.errorMessage;
        });
        
        let err = (errorMessage && errorMessage.error && errorMessage.error != '') ? errorMessage.error : null;
        if(err){
          Utils.toast(<div style={{ fontWeight: 'bold' }}>{errorMessage.error}</div>, 'error');
        }
        
        dispatch(actions.finishJobs({ data: null, errorMessage: errorMessage, isError: isError, billingType: billingType }));
      });
    },

    callPushJobInvoicesApi: (params = null, hubId = null, callback = null) => async (dispatch, getState) => {
      dispatch(actions.startPushInvoices());

      const { billingType, weightChitJobIds } = getState().invoiceNumber;


      let wcJobIds = [];
      if(weightChitJobIds && weightChitJobIds.length > 0){
        weightChitJobIds.forEach((site) => {
          if(site && site.jobs && site.jobs.length > 0){
            site.jobs.forEach((jobId) => {
              if(jobId){
                wcJobIds.push(jobId);
              }
            });
          }
        });
      }


      let dataParams = [];
      if(params && params.length > 0){
        for(let i = 0; i < params.length; i++){
          let customer = (params[i] && params[i].customer) ? params[i].customer : null;

          let customerId = (customer && customer.customerId) ? customer.customerId : null;
          let customerName = (customer && customer.customerName) ? customer.customerName : null;
          let billingName = (customer && customer.billingName) ? customer.billingName : null;
          let billingAddress = (customer && customer.billingAddress) ? customer.billingAddress : null;
          let accountBillingProfileId = (customer && customer.accountBillingProfileId) ? customer.accountBillingProfileId : null;
          let xeroPaymentTerm = (customer && customer.xeroPaymentTerm) ? customer.xeroPaymentTerm : null;
          let xeroPaymentTermType = (customer && customer.xeroPaymentTermType) ? customer.xeroPaymentTermType.toString() : '0';
          let quickBookDefaultTermId = (customer && customer.quickBookDefaultTermId) ? customer.quickBookDefaultTermId : null;

          let billingProfile = (customer && customer.billingProfile) ? customer.billingProfile : null;
          let invoiceNumber = (billingProfile && billingProfile.invoiceNumber) ? billingProfile.invoiceNumber : null;
          let purchaseOrderNumber = (billingProfile && billingProfile.purchaseOrderNumber) ? billingProfile.purchaseOrderNumber : null;
          let expenseNumber = (billingProfile && billingProfile.expenseNumber) ? billingProfile.expenseNumber : null;
          let invoiceDate = (billingProfile && billingProfile.invoiceDate) ? billingProfile.invoiceDate : null;


          let idsArr = [];
          if(params[i] && params[i].siteJobs && params[i].siteJobs.length > 0){
            for(let j = 0; j < params[i].siteJobs.length; j++){
              
              if(params[i].siteJobs[j] && params[i].siteJobs[j].jobs && params[i].siteJobs[j].jobs.length > 0){
                for(let k = 0; k < params[i].siteJobs[j].jobs.length; k++){

                  if(params[i].siteJobs[j].jobs[k].jobId && params[i].siteJobs[j].jobs[k].jobId > 0){
                    idsArr.push(params[i].siteJobs[j].jobs[k].jobId);
                  }

                }
              }

            }
          }

          
          let ids = (idsArr && idsArr.length > 0) ? idsArr.join(',').replace(/,+(?=,|$)/g, '').trim() : '';
          let isCombined = (idsArr.length > 1) ? true : (idsArr.length === 1) ? false : null;
          let wcIds = (wcJobIds && wcJobIds.length > 0) ? wcJobIds.join(',').replace(/,+(?=,|$)/g, '').trim() : '';
          if((ids != '') && (isCombined != null)){
            let dp = {
              jobIds: ids,
              isCombined: isCombined,
              weightChitJobIds: wcIds,
            };
            if(invoiceDate){
              dp[(billingType == BillingTypes.Purchase) ? 'invoiceDate' : (billingType == BillingTypes.Expense) ? 'expenseDate' : 'invoiceDate'] = invoiceDate;
            }
            if(invoiceNumber){
              dp[(billingType == BillingTypes.Purchase) ? 'purchaseOrderNumber' : (billingType == BillingTypes.Expense) ? 'expenseNumber' : 'invoiceNumber'] = invoiceNumber;
            }
            if(customer){
              dp['customer'] = {
                customerId: customerId,
                customerName: customerName,
                billingName: billingName,
                billingAddress: billingAddress,
                accountBillingProfileId: accountBillingProfileId,
                xeroPaymentTerm: xeroPaymentTerm,
                xeroPaymentTermType: xeroPaymentTermType,
                quickBookDefaultTermId: quickBookDefaultTermId,
              };
            }
            if(billingType == BillingTypes.Purchase){
              dp['includePurchaseOrderNumber'] = true;
            } else if(billingType == BillingTypes.Expense){
              dp['includeExpenseNumber'] = true;
            }
            dataParams.push(dp);
          }

        }
      }
      
      let path = (billingType == BillingTypes.Purchase) ? 'job/billing/batch-purchase-order' : (billingType == BillingTypes.Expense) ? 'job/billing/batch-expense' : 'job/billing/batch-invoice';

      if(dataParams && dataParams.length > 0){
        await dispatchCrudApi.createApi({ data: dataParams, hubId: hubId }, path).then(result => {
          let data = result.data;

          let failedItems = [];
          if(data && data.failedJobs && data.failedJobs.length > 0){
            params = params.map((customer) => {
              let updatedSiteJobs1 = [];
              let updatedSiteJobs = customer.siteJobs.map((site) => {
                let updatedJobs1 = [];
                let updatedJobs = site.jobs.map((job) => {
                  if (data.failedJobs.map(x => x.jobId).includes(job.jobId)) {
                    let failedJobItem = data.failedJobs.find(x => x.jobId == job.jobId);
                    let obj = { ...job, response: failedJobItem, isOK: 'false' }

                    if(failedJobItem){
                      updatedJobs1.push(obj);
                    }

                    return obj;
                  }
                  return job;
                });
                updatedSiteJobs1.push({ ...site, jobs: updatedJobs1 });

                return {
                  ...site,
                  jobs: updatedJobs,
                };
              });
              failedItems.push({ ...customer, siteJobs: updatedSiteJobs1 });
              
              return {
                ...customer,
                siteJobs: updatedSiteJobs,
              };
            });
          }

          let successItems = [];
          if(data && data.successJobs && data.successJobs.length > 0){
            params = params.map((customer) => {
              let updatedSiteJobs1 = [];
              let updatedSiteJobs = customer.siteJobs.map((site) => {
                let updatedJobs1 = [];
                let updatedJobs = site.jobs.map((job) => {
                  if (data.successJobs.map(x => x.jobId).includes(job.jobId)) {
                    let successJobItem = data.successJobs.find(x => x.jobId == job.jobId);
                    let obj = { ...job, response: successJobItem, isOK: 'true' }
                    
                    if(successJobItem){
                      updatedJobs1.push(obj);
                    }

                    return obj;
                  }
                  return job;
                });
                updatedSiteJobs1.push({ ...site, jobs: updatedJobs1 });
          
                return {
                  ...site,
                  jobs: updatedJobs,
                };
              });
              successItems.push({ ...customer, siteJobs: updatedSiteJobs1 });
          
              return {
                ...customer,
                siteJobs: updatedSiteJobs,
              };
            });
          }

          failedItems = failedItems.filter((customer) =>
            customer.siteJobs.some((site) =>
              site.jobs.some((job) => job.isOK == 'false')
            )
          );

          successItems = successItems.filter((customer) =>
            customer.siteJobs.some((site) =>
              site.jobs.some((job) => job.isOK == 'true')
            )
          );

          if(callback){
            let failedJobIds = [];
            if(failedItems && failedItems.length > 0){
              failedItems.forEach(c => {
                if(c && c.siteJobs && c.siteJobs.length > 0){
                  c.siteJobs.forEach(s => {
                    if(s && s.jobs && s.jobs.length > 0){
                      s.jobs.forEach(j => {
                        failedJobIds.push(j.jobId);
                      });
                    }
                  });
                }
              });
            }
            callback(failedJobIds);
          }

          dispatch(actions.finishPushInvoices({
            failedItems: failedItems,
            successItems: successItems,
            items: params,
          }));
        }).catch(error => {
          let isError = true;
          let errorMessage = "";
  
          Utils.parseError(error, (result) => {
            isError = result.isError;
            errorMessage = result.errorMessage;
          });
          
          let err = (errorMessage && errorMessage.error && errorMessage.error != '') ? errorMessage.error : null;
          if(err){
            Utils.toast(<div style={{ fontWeight: 'bold' }}>{errorMessage.error}</div>, 'error');
          }
          
          dispatch(actions.cancelPushInvoices());
        });
      } else {
        Utils.toast(<div style={{ fontWeight: 'bold' }}>{'Cannot create invoices without jobs'}</div>, 'error');
        dispatch(actions.cancelPushInvoices());
      }
    },

    callReadFailedJobsApi: (failedJobIds = []) => async (dispatch, getState) => {
      const { params } = getState().invoiceNumber;

      let data = (params && params.data != null) ? _.cloneDeep(params.data) : null;

      let arr = (failedJobIds && failedJobIds.length > 0) ? failedJobIds : [];
      let billingType = (data && data.billingType != null) ? data.billingType : null;

      dispatch(actions.startReadFailedJobs());

      let ids = arr.join(',').replace(/,+(?=,|$)/g, '').trim();

      data['jobIds'] = ids;
      data['includeJobs'] = true;

      await dispatchCrudApi.createApi(data, 'job/billing/customer').then(result => {
        let data = result.data;
        
        dispatch(actions.finishReadFailedJobs({ data: data, errorMessage: '', isError: false, billingType: billingType }));
      }).catch(error => {
        let isError = true;
        let errorMessage = "";

        Utils.parseError(error, (result) => {
          isError = result.isError;
          errorMessage = result.errorMessage;
        });
        
        let err = (errorMessage && errorMessage.error && errorMessage.error != '') ? errorMessage.error : null;
        if(err){
          Utils.toast(<div style={{ fontWeight: 'bold' }}>{errorMessage.error}</div>, 'error');
        }
        
        dispatch(actions.finishReadFailedJobs({ data: null, errorMessage: errorMessage, isError: isError, billingType: billingType }));
      });
    },

    callRetryApi: (params = null) => async (dispatch, getState) => {
      const { billingType, weightChitJobIds } = getState().invoiceNumber;

      dispatch(actions.startRetry());

      
      let wcJobIds = [];
      if(weightChitJobIds && weightChitJobIds.length > 0){
        weightChitJobIds.forEach((site) => {
          if(site && site.jobs && site.jobs.length > 0){
            site.jobs.forEach((jobId) => {
              if(jobId){
                wcJobIds.push(jobId);
              }
            });
          }
        });
      }


      let dp = {}
      if(params){
        let customer = (params && params.customer) ? params.customer : null;

        let customerId = (customer && customer.customerId) ? customer.customerId : null;
        let customerName = (customer && customer.customerName) ? customer.customerName : null;
        let billingName = (customer && customer.billingName) ? customer.billingName : null;
        let billingAddress = (customer && customer.billingAddress) ? customer.billingAddress : null;
        let accountBillingProfileId = (customer && customer.accountBillingProfileId) ? customer.accountBillingProfileId : null;
        let xeroPaymentTerm = (customer && customer.xeroPaymentTerm) ? customer.xeroPaymentTerm : null;
        let xeroPaymentTermType = (customer && customer.xeroPaymentTermType) ? customer.xeroPaymentTermType.toString() : '0';
        let quickBookDefaultTermId = (customer && customer.quickBookDefaultTermId) ? customer.quickBookDefaultTermId : null;

        let billingProfile = (customer && customer.billingProfile) ? customer.billingProfile : null;
        let invoiceNumber = (billingProfile && billingProfile.invoiceNumber) ? billingProfile.invoiceNumber : null;
        let invoiceDate = (billingProfile && billingProfile.invoiceDate) ? billingProfile.invoiceDate : null;


        let idsArr = [];
        if(params && params.siteJobs && params.siteJobs.length > 0){
          for(let j = 0; j < params.siteJobs.length; j++){
            
            if(params.siteJobs[j] && params.siteJobs[j].jobs && params.siteJobs[j].jobs.length > 0){
              for(let k = 0; k < params.siteJobs[j].jobs.length; k++){

                if(params.siteJobs[j].jobs[k].jobId && params.siteJobs[j].jobs[k].jobId > 0){
                  idsArr.push(params.siteJobs[j].jobs[k].jobId);
                }

              }
            }

          }
        }
        
        let ids = (idsArr && idsArr.length > 0) ? idsArr.join(',').replace(/,+(?=,|$)/g, '').trim() : '';
        let isCombined = (idsArr.length > 1) ? true : (idsArr.length === 1) ? false : null;
        let wcIds = (wcJobIds && wcJobIds.length > 0) ? wcJobIds.join(',').replace(/,+(?=,|$)/g, '').trim() : '';
        if((ids != '') && (isCombined != null)){
          dp = {
            jobIds: ids,
            isCombined: isCombined,
            weightChitJobIds: wcIds,
          };
          if(invoiceDate){
            dp[(billingType == BillingTypes.Purchase) ? 'invoiceDate' : (billingType == BillingTypes.Expense) ? 'expenseDate' : 'invoiceDate'] = invoiceDate;
          }
          if(invoiceNumber){
            dp[(billingType == BillingTypes.Purchase) ? 'purchaseOrderNumber' : (billingType == BillingTypes.Expense) ? 'expenseNumber' : 'invoiceNumber'] = invoiceNumber;
          }
          if(customer){
            dp['customer'] = {
              customerId: customerId,
              customerName: customerName,
              billingName: billingName,
              billingAddress: billingAddress,
              accountBillingProfileId: accountBillingProfileId,
              xeroPaymentTerm: xeroPaymentTerm,
              xeroPaymentTermType: xeroPaymentTermType,
              quickBookDefaultTermId: quickBookDefaultTermId,
            };
          }
          if(billingType == BillingTypes.Purchase){
            dp['includePurchaseOrderNumber'] = true;
          } else if(billingType == BillingTypes.Expense){
            dp['includeExpenseNumber'] = true;
          }
        }
      }

      // let path = (billingType == BillingTypes.Purchase) ? 'job/billing/purchase-order' : 'job/billing/invoice';
      let path = (billingType == BillingTypes.Purchase) ? 'job/billing/batch-purchase-order' : (billingType == BillingTypes.Expense) ? 'job/billing/batch-expense' : 'job/billing/batch-invoice';

      await dispatchCrudApi.createApi({ data: [dp] }, path).then(result => {
        let data = result.data;
        
        dispatch(actions.finishRetry({ data: data, errorMessage: '', isError: false }));
      }).catch(error => {
        let isError = true;
        let errorMessage = "";

        Utils.parseError(error, (result) => {
          isError = result.isError;
          errorMessage = result.errorMessage;
        });
        
        let err = (errorMessage && errorMessage.error && errorMessage.error != '') ? errorMessage.error : null;
        if(err){
          Utils.toast(<div style={{ fontWeight: 'bold' }}>{errorMessage.error}</div>, 'error');
        }
        
        dispatch(actions.cancelRetry());
      });
    },
    
    callRetryAllApi: (params = null, hubId = null) => async (dispatch, getState) => {
      const { billingType, weightChitJobIds } = getState().invoiceNumber;

      dispatch(actions.startRetryAll());

      
      let wcJobIds = [];
      if(weightChitJobIds && weightChitJobIds.length > 0){
        weightChitJobIds.forEach((site) => {
          if(site && site.jobs && site.jobs.length > 0){
            site.jobs.forEach((jobId) => {
              if(jobId){
                wcJobIds.push(jobId);
              }
            });
          }
        });
      }


      let dataParams = [];
      if(params && params.length > 0){
        for(let i = 0; i < params.length; i++){
          let customer = (params[i] && params[i].customer) ? params[i].customer : null;

          let customerId = (customer && customer.customerId) ? customer.customerId : null;
          let customerName = (customer && customer.customerName) ? customer.customerName : null;
          let billingName = (customer && customer.billingName) ? customer.billingName : null;
          let billingAddress = (customer && customer.billingAddress) ? customer.billingAddress : null;
          let accountBillingProfileId = (customer && customer.accountBillingProfileId) ? customer.accountBillingProfileId : null;
          let xeroPaymentTerm = (customer && customer.xeroPaymentTerm) ? customer.xeroPaymentTerm : null;
          let xeroPaymentTermType = (customer && customer.xeroPaymentTermType) ? customer.xeroPaymentTermType.toString() : '0';
          let quickBookDefaultTermId = (customer && customer.quickBookDefaultTermId) ? customer.quickBookDefaultTermId : null;

          let billingProfile = (customer && customer.billingProfile) ? customer.billingProfile : null;
          let invoiceNumber = (billingProfile && billingProfile.invoiceNumber) ? billingProfile.invoiceNumber : null;
          let purchaseOrderNumber = (billingProfile && billingProfile.purchaseOrderNumber) ? billingProfile.purchaseOrderNumber : null;
          let expenseNumber = (billingProfile && billingProfile.expenseNumber) ? billingProfile.expenseNumber : null;
          let invoiceDate = (billingProfile && billingProfile.invoiceDate) ? billingProfile.invoiceDate : null;


          let idsArr = [];
          if(params[i] && params[i].siteJobs && params[i].siteJobs.length > 0){
            for(let j = 0; j < params[i].siteJobs.length; j++){
              
              if(params[i].siteJobs[j] && params[i].siteJobs[j].jobs && params[i].siteJobs[j].jobs.length > 0){
                for(let k = 0; k < params[i].siteJobs[j].jobs.length; k++){

                  if(params[i].siteJobs[j].jobs[k].jobId && params[i].siteJobs[j].jobs[k].jobId > 0){
                    idsArr.push(params[i].siteJobs[j].jobs[k].jobId);
                  }

                }
              }

            }
          }

          
          let ids = (idsArr && idsArr.length > 0) ? idsArr.join(',').replace(/,+(?=,|$)/g, '').trim() : '';
          let isCombined = (idsArr.length > 1) ? true : (idsArr.length === 1) ? false : null;
          let wcIds = (wcJobIds && wcJobIds.length > 0) ? wcJobIds.join(',').replace(/,+(?=,|$)/g, '').trim() : '';
          if((ids != '') && (isCombined != null)){
            let dp = {
              jobIds: ids,
              isCombined: isCombined,
              weightChitJobIds: wcIds,
            };
            if(invoiceDate){
              dp[(billingType == BillingTypes.Purchase) ? 'invoiceDate' : (billingType == BillingTypes.Expense) ? 'expenseDate' : 'invoiceDate'] = invoiceDate;
            }
            if(invoiceNumber){
              dp[(billingType == BillingTypes.Purchase) ? 'purchaseOrderNumber' : (billingType == BillingTypes.Expense) ? 'expenseNumber' : 'invoiceNumber'] = invoiceNumber;
            }
            if(customer){
              dp['customer'] = {
                customerId: customerId,
                customerName: customerName,
                billingName: billingName,
                billingAddress: billingAddress,
                accountBillingProfileId: accountBillingProfileId,
                xeroPaymentTerm: xeroPaymentTerm,
                xeroPaymentTermType: xeroPaymentTermType,
                quickBookDefaultTermId: quickBookDefaultTermId,
              };
            }
            if(billingType == BillingTypes.Purchase){
              dp['includePurchaseOrderNumber'] = true;
            } else if(billingType == BillingTypes.Expense){
              dp['includeExpenseNumber'] = true;
            }
            dataParams.push(dp);
          }

        }
      }

      let path = (billingType == BillingTypes.Purchase) ? 'job/billing/batch-purchase-order' : (billingType == BillingTypes.Expense) ? 'job/billing/batch-expense' : 'job/billing/batch-invoice';

      await dispatchCrudApi.createApi({ data: dataParams, hubId: hubId }, path).then(result => {
        let data = result.data;

          dispatch(actions.finishRetryAll({ data: data, errorMessage: '', isError: false }));
      }).catch(error => {
        let isError = true;
        let errorMessage = "";

        Utils.parseError(error, (result) => {
          isError = result.isError;
          errorMessage = result.errorMessage;
        });
        
        let err = (errorMessage && errorMessage.error && errorMessage.error != '') ? errorMessage.error : null;
        if(err){
          Utils.toast(<div style={{ fontWeight: 'bold' }}>{errorMessage.error}</div>, 'error');
        }
        
        dispatch(actions.cancelRetryAll());
      });
    },

    getAllJobIds: () => async (dispatch, getState) => {
      const { status, failedItems, successItems } = getState().invoiceNumber;

      let failedItemsX = failedItems;
      let successItemsX = successItems;

      dispatch(actions.hide());

      if(status){
        dispatch(checkboxCount.clear());
      }

      let customers = [];

      let failedJobs = [];
      let failedJobIds = [];
      if(failedItemsX && failedItemsX.length > 0){
        failedItemsX.forEach((customer) => {
          customer.siteJobs.forEach((site) => {
            site.jobs.forEach((job) => {
              if(job.response && job.response.job) {
                failedJobs.push(job.response.job);
              } else {
                failedJobs.push(job);
              }

              failedJobIds.push(job.jobId);
            });
          });

          customers.push(customer.customer);
        });
      }

      let successJobs = [];
      let successJobIds = [];
      if(successItemsX && successItemsX.length > 0){
        successItemsX.forEach((customer) => {
          customer.siteJobs.forEach((site) => {
            site.jobs.forEach((job) => {
              if(job.response && job.response.job) {
                successJobs.push(job.response.job);
              } else {
                successJobs.push(job);
              }

              successJobIds.push(job.jobId);
            });
          });

          customers.push(customer.customer);
        });
      }

      try {
        Utils.colorRowsPrimery(successJobIds);
        Utils.colorRowsDanger(failedJobIds);
      }catch(e){}

      return {
        failedJobs: failedJobs,
        failedJobIds: failedJobIds,
        successJobs: successJobs,
        successJobIds: successJobIds,
        customers: customers,
      }
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
    extraReducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();