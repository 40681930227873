/* eslint-disable no-loop-func */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

import * as dispatchCrudApi from '../../../api/CRUD/DispatchCRUD'
import Utils from "../../../utils/utils";
import { ParseResult } from "../../../utils/interfaces";
import { ActiveBinNumberRequestFilter, BinActivity, PageCookieSettingType } from '../../../utils/enums'


interface InitState {
  isLoading: boolean,
  selected: Array<any>,
  columnVisibility: Array<any>,
  columns: Array<any>,
  rows: Array<any>,
  currentPage: number,
  totalRows: number,
  rowsPerPageSelected: number,
  rowsPerPageOptions: Array<any>,
  search: any,
  sortColumn: string,
  sortDir: string,
  isIncludeInactive: any,
  isInactive: any,

  binTypeId: any,
  activeFilter: any,
  isActive: any,
  binStatus: any,
  isValidated: any,
}


function NewReducer() {
  const name = 'binCenterTable';


  const initialState: InitState = {
    isLoading: false,
    selected: [],
    columnVisibility: [],
    columns: [],
    rows: [],
    currentPage: 1,
    totalRows: 0,
    rowsPerPageSelected: 50,
    rowsPerPageOptions: [50, 100, 300],
    search: '',
    sortColumn: 'daysOut',
    sortDir: 'desc',
    isIncludeInactive: null,
    isInactive: false,

    binTypeId: null,
    activeFilter: null,
    isActive: true,
    binStatus: null,
    isValidated: null,
  };


  const reducers = {
    tableGetFilters: (state: InitState, action: PayloadAction<{ auth: any, settingsType: any, pageName: any }>) => {
      const auth = (action.payload.auth) ? action.payload.auth : null;
      const settingsType = (action.payload.settingsType) ? action.payload.settingsType : null;
      const pageName = (action.payload.pageName) ? action.payload.pageName : null;

      let settingsItem = null;
      if(settingsType){
        const { userCookieSettings } = auth;
        settingsItem = Utils.getUserCookieSettings(userCookieSettings, settingsType, pageName);
      }

      let rowsPerPageSelectedValue = settingsItem && settingsItem[PageCookieSettingType.rowsPerPageSelected] && settingsItem[PageCookieSettingType.rowsPerPageSelected] !== "" ? settingsItem[PageCookieSettingType.rowsPerPageSelected] : initialState.rowsPerPageSelected;
      
      state.rowsPerPageSelected = rowsPerPageSelectedValue;
    },
    tableSetFilters: (state: InitState, action: PayloadAction<{ auth: any, settingsType: any, pageName: any }>) => {
      const auth = (action.payload.auth) ? action.payload.auth : null;
      const settingsType = (action.payload.settingsType) ? action.payload.settingsType : null;
      const pageName = (action.payload.pageName) ? action.payload.pageName : null;

      if(settingsType){
        let { token, userCookieSettings } = auth;
  
        let data: any = {
          name: pageName,
          type: settingsType,
        };
  
        let value = {
          [PageCookieSettingType.rowsPerPageSelected]: state.rowsPerPageSelected,
        };
        data['value'] = JSON.stringify(value);
  
        Utils.saveUserCookieSettings(data, token, (settings: any) => {
          if(settings){
            let settingsArr = (userCookieSettings && userCookieSettings.length > 0) ? userCookieSettings : [];
            let settingsIndex = Utils.getUserCookieSettingsIndex(settingsArr, settingsType);
            if(settingsIndex !== -1 && settingsArr[settingsIndex]){
              let oldValue = JSON.parse(settingsArr[settingsIndex].value);
              let newValue = JSON.parse(settings.value);

              let value = Object.assign({}, oldValue);
              value[PageCookieSettingType.rowsPerPageSelected] = newValue[PageCookieSettingType.rowsPerPageSelected];
              
              settingsArr[settingsIndex].updated = settings.updated;
              settingsArr[settingsIndex].value = JSON.stringify(value);
            } else {
              settingsArr.push(settings);
            }
          }
        });
      }
    },
    
    setColumns: (state: InitState, action: PayloadAction<any>) => {
      state.columns = action.payload;
    },
    setColumnsVisibility: (state: InitState, action: PayloadAction<any>) => {
      state.columnVisibility = action.payload;
    },
    setSearchParams: (state: InitState, action: PayloadAction<any>) => {
      state.search = action.payload;
      state.currentPage = 1;
    },
    setSortParams: (state: InitState, action: PayloadAction<{ sortColumn: any, sortDir: any }>) => {
      state.sortColumn = action.payload.sortColumn;
      state.sortDir = action.payload.sortDir;
    },
    setCurrentPage: (state: InitState, action: PayloadAction<any>) => {
      state.currentPage = action.payload;
    },
    setPerPageSelected: (state: InitState, action: PayloadAction<any>) => {
      state.rowsPerPageSelected = action.payload;
      state.currentPage = 1;
    },

    startClearFilter: (state: InitState) => {
      state.binTypeId = null;
      state.activeFilter = null;
      state.isActive = null;
      state.binStatus = null;
    },

    startRead: (state: InitState) => {
      state.isLoading = true;
    },
    finishRead: (state: InitState, action: PayloadAction<{ data: any, total: any }>) => {
      let data = (action.payload && action.payload.data) ? action.payload.data : [];
      let total = (action.payload && action.payload.total) ? action.payload.total : 0;

      state.rows = data;
      state.totalRows = total;
      state.isLoading = false;
    },
    
    startBinTypeId: (state: InitState, action: PayloadAction<{ id: any, checked: boolean }>) => {
      let id = action.payload.id;
      let checked = action.payload.checked;

      let binTypeId = null;
      if(!checked){
        binTypeId = id;
      } else {
        binTypeId = null
      }

      state.binTypeId = binTypeId;
    },

    startActiveFilter: (state: InitState, action: PayloadAction<{ id: any, checked: boolean }>) => {
      let id = action.payload.id;
      let checked = action.payload.checked;

      let activeFilter = null;
      if(!checked){
        if(Utils.isNumber(id)){
          if(id.toString() == ActiveBinNumberRequestFilter.Seven.toString()){
            activeFilter = ActiveBinNumberRequestFilter.Seven
          } else if(id.toString() == ActiveBinNumberRequestFilter.Fourteen.toString()){
            activeFilter = ActiveBinNumberRequestFilter.Fourteen
          } else if(id.toString() == ActiveBinNumberRequestFilter.TwentyOne.toString()){
            activeFilter = ActiveBinNumberRequestFilter.TwentyOne
          } else if(id.toString() == ActiveBinNumberRequestFilter.ThirtyPlus.toString()){
            activeFilter = ActiveBinNumberRequestFilter.ThirtyPlus
          } else {
            activeFilter = null;
          }
        } else {
          activeFilter = null;
        }
      } else {
        activeFilter = null
      }

      state.activeFilter = activeFilter;
    },

    startIsActive: (state: InitState, action: PayloadAction<{ id: any, checked: boolean }>) => {
      let id = action.payload.id;
      let checked = action.payload.checked;

      let isActive = null;
      if(!checked){
        if (id == 'Inactive') {
          isActive = false
        } else if (id == 'Active') {
          isActive = true
        } else {
          isActive = null
        }
      } else {
        isActive = null
      }

      state.isActive = isActive;
    },
    
    startBinStatus: (state: InitState, action: PayloadAction<{ id: any, checked: boolean }>) => {
      let id = action.payload.id;
      let checked = action.payload.checked;

      let binStatus = null;
      if(!checked){
        if(Utils.isNumber(id)){
          if(id.toString() == BinActivity.IN.toString()){
            binStatus = BinActivity.IN
          } else if(id.toString() == BinActivity.OUT.toString()){
            binStatus = BinActivity.OUT
          } else if(id.toString() == BinActivity.NEED_ATTENTION.toString()){
            binStatus = BinActivity.NEED_ATTENTION
          } else {
            binStatus = null;
          }
        } else {
          binStatus = null;
        }
      } else {
        binStatus = null
      }

      state.binStatus = binStatus;
    },
    
    startIsValidated: (state: InitState, action: PayloadAction<any>) => {
      state.isValidated = action.payload;
    },
  };


  const apis = {
    callReadApi: (param: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startRead());

      await dispatchCrudApi.readApi(param, 'bin-center/bin-on-site-list').then(result => {
        let data = result.data.data;
        let total = result.data.total;
        
        callback(true, data);
        dispatch(actions.finishRead({ data, total }));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        callback(false, null);
        dispatch(actions.finishRead({ data: null, total: 0 }));
      });
    },

    setClearFilter: (callback: () => void) => async (dispatch: any) => {
      await dispatch(actions.startClearFilter());
      callback();
    },

    setBinTypeId: (param: any, callback: () => void) => async (dispatch: any) => {
      await dispatch(actions.startBinTypeId(param));
      callback();
    },
    
    setActiveFilter: (param: any, callback: () => void) => async (dispatch: any) => {
      await dispatch(actions.startActiveFilter(param));
      callback();
    },

    setIsActive: (param: any, callback: () => void) => async (dispatch: any) => {
      await dispatch(actions.startIsActive(param));
      callback();
    },
    
    setBinStatus: (param: any, callback: () => void) => async (dispatch: any) => {
      await dispatch(actions.startBinStatus(param));
      callback();
    },
    
    setIsValidated: (param: any, callback: () => void) => async (dispatch: any) => {
      await dispatch(actions.startIsValidated(param));
      callback();
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();